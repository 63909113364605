<template>
    <div class="piclist">
        <!-- 下方的筛选项目连带搜索 -->
        <div class="search-box" v-if="list && listshow">
            <div class="search_inp">
                <el-input placeholder="请输入素材名称" prefix-icon="el-icon-search" size="small" v-model="name" clearable @input="inputBtn" @clear="reset" @keyup.enter.native="reset">
                </el-input>
            </div>
        </div>
        <!-- 列表标题 -->
        <div class="list-title" v-if="list && list.length">
            <div class="title">视频</div>
            <div class="icon">
                <span v-if="!listshow" @click="listshowchange(true)">更多<i class="el-icon-arrow-right"></i></span>
                <i class="el-icon-arrow-left" v-else @click="listshowchange(false)"></i>
            </div>
        </div>
        <!-- 列表 -->
        <div class="contentbox" v-if="!listshow">
            <el-checkbox-group ref="selectids" v-model="checkList">
                <div class="item openHover" v-for="(item, x) in list.slice(0, 3)" :key="x">
                    <div class="item-pic">
                        <div class="picbox">
                            <el-image style="width: 96px; height: 70px" :src='item.thumbnail' fit="contain">
                                <div slot="error" class="image-slot">
                                    <img src="@/assets/images/icon/img.png" alt="">
                                </div>
                            </el-image>
                        </div>
                        <!-- 左上角的眼睛 -->
                        <div class="preview" @click="look(item.url)">
                            <i class="el-icon-view color"></i>
                        </div>
                        <!-- 下方的插入按钮 -->
                        <div class="insert">
                            <div class="button" @click="selectMaterials(item)">插入</div>
                        </div>
                    </div>
                </div>
            </el-checkbox-group>
        </div>
        <div class="contentbox scroll_bar" v-else infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
            <el-checkbox-group v-model="checkList">
                <div class="item openHover" v-for="(item, x) in list" :key="x" :class="{ 'selectHover': listshow }" @change="checkChange(item)" @mouseenter="mouseenter(item)" @mouseleave="mouseleave(item)">
                    <div class="item-pic">
                        <div class="picbox">
                            <el-image style="width: 96px; height: 70px" :src='item.thumbnail' fit="contain">
                                <div slot="error" class="image-slot">
                                    <img src="@/assets/images/icon/img.png" alt="">
                                </div>
                            </el-image>
                        </div>
                        <!-- 左上角的眼睛 -->
                        <div class="preview" @click="look(item.url)">
                            <i class="el-icon-view color"></i>
                        </div>
                        <!-- 下方的插入按钮 -->
                        <div class="insert">
                            <div class="button" @click="selectMaterials(item)">插入</div>
                        </div>
                        <!-- 左边的选中框 -->
                        <div class="selectCheck" v-if="item.checked || item.flag">
                            <el-checkbox :label="item.id"><br /></el-checkbox>
                        </div>
                    </div>
                </div>
            </el-checkbox-group>
            <!-- 没有更多 -->
            <Loading :loading="loading" :list="list" :page="page" :totalPage="last_page">
            </Loading>
        </div>
        <!-- 预览 -->
        <!-- <ImgPreview ref="ImgPreview"></ImgPreview> -->
        <VideoPreview ref="VideoPreview"></VideoPreview>
        <video v-if="show" :src="url" id="video"></video>
    </div>
</template>

<script>
import {
    mapState
} from "vuex"
export default {
    components: {
        VideoPreview: resolve => require(['@/components/video_preview/index'], resolve),
        Loading: (resolve) => require(['@/components/loading/index'], resolve)

    },
    props: {
        listshow: {
            type: Boolean,
            default: false
        },
        // list: {
        //   type: Array,
        //   default: () => []
        // },
        showtype: {
            type: String,
            default: 'thumbnail'
        }
    },
    data() {
        return {
            checkList: [],
            name: '',
            isSelect: false, // 收否开启选择模式
            checkedAll: false, // 选择所有
            loading: false,
            page: 0,
            last_page: 1, // 总页数
            limit: 20,
            list: [],
            url: '',
            show: false,
        }
    },
    created() {
        this.load()

    },
    watch: {
        checkList(value) {
            this.$emit('upDataDelete', value)
        },
        list(value) {
            this.$emit('getList', value)
        }
    },
    computed: {
        ...mapState({
            currentTime: state => state.videoData.currentTime,//当前播放时间
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
            duration: state => state.videoData.duration, //音频总时长
            sizeRatio: state => state.videoCreate.sizeRatio,//宽高比2=9:16 1=16:9
        }),
        searchForm() {
            return {
                page: this.page,
                limit: this.limit,
                type: 4,
                name: this.name
            }
        },
    },
    methods: {
        inputBtn() { },
        // 加载更多
        load() {
            if (this.loading || this.page === this.last_page || this.last_page === 0) return
            this.page++
            this.getList()
        },
        // 重置
        reset() {
            this.list = []
            this.page = 1
            this.checkedAll = false
            this.deleteIds = []
            this.getList()
        },
        getList() {
            this.loading = true
            this.$workbenApi.self_material({
                ...this.searchForm
            }).then(res => {
                this.list = [...this.list, ...res?.result?.data] || []
                this.loading = false
                this.last_page = res?.result?.last_page
                this.page = res?.result?.current_page ?? 1
            })
        },
        listshowchange(e) {
            this.$emit('listshowchange', e)
        },
        // 插入素材
        selectMaterials(info) {
            var that = this
            that.show = true
            that.url = info.url
            var arr = JSON.parse(JSON.stringify(that.sceneList))
            var idx = that.sceneIndex
            var scenes = arr[idx].scenes_conf || []
            var obj = {
                width: 0,
                height: 0,
                left: 0,
                top: 0,
                rotation: 0
            }
            that.$nextTick(() => {
                const video = document.getElementById('video')
                // 获取视频宽高
                video.addEventListener('canplay', function (e) {
                    var width = e.target.videoWidth
                    var height = e.target.videoHeight
                    if (that.sizeRatio == 1) {//16:9
                        if (width < 1920 && height < 1080) {
                            obj.width = width
                            obj.height = height
                            obj.left = Math.floor((1920 - width) / 2)
                            obj.top = Math.floor((1080 - height) / 2)
                        } else if (width > 1920 && height < 1080) {
                            var ratio = (1920 / width).toFixed(5)
                            obj.width = 1920
                            obj.height = Math.floor(height * ratio)
                            obj.left = 0
                            obj.top = Math.floor((1080 - obj.height) / 2)
                        } else if (width < 1920 && height > 1080) {
                            var ratio = (1080 / height).toFixed(5)
                            obj.width = Math.floor(width * ratio)
                            obj.height = 1080
                            obj.left = Math.floor((1920 - obj.width) / 2)
                            obj.top = 0
                        } else {
                            var ratio = ''
                            var ratio1 = (1920 / width).toFixed(5)
                            var ratio2 = (1080 / height).toFixed(5)
                            if ((ratio1 * 100000) > (ratio2 * 100000)) {
                                ratio = ratio2
                            } else {
                                ratio = ratio1
                            }
                            obj.width = Math.floor(width * ratio)
                            obj.height = Math.floor(height * ratio)
                            obj.left = Math.floor((1920 - obj.width) / 2)
                            obj.top = Math.floor((1080 - obj.height) / 2)
                        }
                    }
                    if (that.sizeRatio == 2) {//9:16
                        if (width < 1080 && height < 1920) {
                            obj.width = width
                            obj.height = height
                            obj.left = Math.floor((1080 - width) / 2)
                            obj.top = Math.floor((1920 - height) / 2)
                        } else if (width > 1080 && height < 1920) {
                            var ratio = (1080 / width).toFixed(5)
                            obj.width = 1080
                            obj.height = Math.floor(height * ratio)
                            obj.left = 0
                            obj.top = Math.floor((1920 - obj.height) / 2)
                        } else if (width < 1080 && height > 1920) {
                            var ratio = (1920 / height).toFixed(5)
                            obj.width = Math.floor(width * ratio)
                            obj.height = 1920
                            obj.left = Math.floor((1080 - obj.width) / 2)
                            obj.top = 0
                        } else {
                            var ratio = ''
                            var ratio1 = (1080 / width).toFixed(5)
                            var ratio2 = (1920 / height).toFixed(5)
                            if ((ratio1 * 100000) > (ratio2 * 100000)) {
                                ratio = ratio2
                            } else {
                                ratio = ratio1
                            }
                            obj.width = Math.floor(width * ratio)
                            obj.height = Math.floor(height * ratio)
                            obj.left = Math.floor((1080 - obj.width) / 2)
                            obj.top = Math.floor((1920 - obj.height) / 2)
                        }
                    }
                    if (that.duration == 0) {
                        return
                    }
                    var str = {
                        id: new Date().getTime(),
                        type: 'video',
                        name: info.name,
                        url: info.url,
                        thumbnail: info.thumbnail,
                        start: that.currentTime * 1000,
                        end: that.duration * 1000,
                        loop: false,
                        audio: false,
                        lock: false,
                        style: obj
                    }
                    scenes.push(str)
                    var params = {
                        type: 'conf',
                        info: scenes
                    }
                    that.$store.dispatch('videoData/modifyProduct', params)
                    that.show = false
                    // 插入后选中
                    that.$store.commit('videoData/SET_SHOW_ID', str.id)
                })
            })
        },
        look(url) {
            this.$refs.VideoPreview.openDialogBtn(url)
        },
        // 全选
        changeSelect(value) {
            this.checkList = value
            if (value.length) {
                this.list.forEach(item => {
                    this.$set(item, 'checked', true)
                })
            } else {
                this.list.forEach(item => {
                    this.$set(item, 'checked', false)
                })
            }
        },
        // 选择
        checkChange(item) {
            var flag = this.checkList.includes(item.id)
            if (flag) {
                this.$set(item, 'checked', true)
            } else {
                this.$set(item, 'checked', false)
            }
        },
        // 鼠标进入
        mouseenter(item) {
            this.$set(item, 'flag', true)

        },
        // 鼠标离开
        mouseleave(item) {
            this.$set(item, 'flag', false)
        },
    }
}
</script>
<style lang="scss" scoped>
@import "../index.scss";

.contentbox {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 100px);
    margin-right: -8px;
}
</style>
